import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { ResponseErrorType, User } from "@/types/model.types";
import { setCookie } from "@/utils/helpers.utils";


type AuthState = {
    isAuthenticated: boolean,
    user: User | null,
    isLoading: boolean,
    errors?: ResponseErrorType
}

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    isLoading: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut: () => {},
        updateLogin: () => {
        }
    },
    extraReducers: (builder)=> {
        builder.addCase(authenticate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            window.localStorage.setItem('_mt_ac_tk', action.payload.data.accessToken)
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.errors = action.payload?.data
        })
    }
})

export const { logOut, updateLogin } = authSlice.actions;

export const authenticate = createAsyncThunk<{data: {accessToken: string } }, { email: string, auth_password: string }, {rejectValue: { data: ResponseErrorType }}>('auth/authenticate', async (payload, {rejectWithValue}) => {
    try {
        const response =  await request.post('/login', {...payload})
        const user = {
            'uname': response.data.data.uname,
            'role': response.data.data.role,
            'email': response.data.data.email
        }
        setCookie('_mt_jt_at', response.data.data.accessToken, 1)
        localStorage.setItem('_user_detail', JSON.stringify(user))
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export default authSlice.reducer;