import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import exampleReducer from './slices/example';
import authReducer from './slices/auth';
import contactReducer from './slices/contact';
import categoryReducer from './slices/category';
import serviceReducer from './slices/service';
import fleetReducer from './slices/fleet';
import fleetRateReducer from './slices/fleet-rate';
import reservationReducer from './slices/reservation';
import adminReducer from "./slices/admin";
import defaultLocationReducer from './slices/default-location';
import { categoryServiceAPI } from './services/category.service';

export function makeStore() {
    return configureStore({
        reducer: combineReducers({
            example: exampleReducer,
            auth: authReducer,
            contact: contactReducer,
            category: categoryReducer,
            service: serviceReducer,
            fleet: fleetReducer,
            fleetrate: fleetRateReducer,
            reservation: reservationReducer,
            admin: adminReducer,
            defaultLocation: defaultLocationReducer,
            [categoryServiceAPI.reducerPath]: categoryServiceAPI.reducer
        }),
        devTools: process.env.NODE_ENV !== "production",
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
        }).concat([categoryServiceAPI.middleware])
    });
}

export const store = makeStore();
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;
