import { Admin, RejectValue, RejectValueAsyncThunk, ResponseErrorType, SuccessResponseType } from "@/types/model.types"
import { createDefaultObject } from "@/utils/object.partial.utils"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { RootState } from "..";


type AdminState = {
    model: Admin | null,
    data: Admin[],
    status: 'saving' | 'fetching' | 'idle' | 'deleting',
    dialogue: boolean,
    errors?:ResponseErrorType
}

const initialState: AdminState = {
    model: createDefaultObject<Admin>() as Admin,
    data: [],
    status: 'idle',
    dialogue: false,
    errors: {
        message: '',
        errors: []
    }
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        adminInputChange(state, { payload }) {
            let {key, value}: {key: keyof Admin, value: any} = payload;
            (state.model as Record<keyof Admin, any>)[key] = value;
        },
        updateAdminState(state, {payload}) {
            const {key, value}: {key: keyof AdminState, value: any} = payload;
            (state as Record<keyof AdminState, any>)[key] = value;
        }
    },
    extraReducers: () => {
        // builder.addCase(submitCategory.pending, (state) => {
        //     state.status = 'saving';
        // });
        // builder.addCase(submitCategory.fulfilled, (state) => {
        //     state.status = 'idle';
        //     state.model = createDefaultObject<Category>() as Category
        // });
        // builder.addCase(submitCategory.rejected, (state, action) => {
        //     state.status = 'idle';
        //     state.errors = action.payload?.data
        // })
    }
})




export const fetchAdmin = createAsyncThunk<{data: SuccessResponseType<Admin[]>}, null, RejectValueAsyncThunk>('admin/fetch', async (_, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateAdminState({ key: 'status', value: 'fetching'}))
        const response = await request.get('/admins')
        dispatch(updateAdminState({key: 'data', value: response.data.rows}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as RejectValue);
    } finally {
        dispatch(updateAdminState({key: 'status', value: 'idle'}))
    }
})
export const fetchSingleAdmin = createAsyncThunk<{data: SuccessResponseType<Admin[]>}, null, RejectValueAsyncThunk>('admin/fetchSingle', async (_, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateAdminState({ key: 'status', value: 'fetching'}))
        const response = await request.get('/admin')
        dispatch(updateAdminState({key: 'data', value: response.data}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as RejectValue);
    }
})

export const submitAdmin = createAsyncThunk<{data: SuccessResponseType<Admin> }, String|null, {rejectValue: { data: ResponseErrorType }}>('admin/submit', async (_, {rejectWithValue, getState, dispatch}) => {
    try {
        const { admin: { model } } = getState() as RootState;
        let response;
        if(!model?.id) {
            response = await request.post('/admins', {...model })
        } else {
            response = await request.put(`/admins/${model?.id}`, {...model })
        }
        dispatch(updateAdminState({key: 'dialogue', value: false}))
        dispatch(updateAdminState({key: 'model', value: null}))
        dispatch(fetchAdmin(null));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export const deleteAdmin = createAsyncThunk<{data: any}, number, {rejectValue: {data: ResponseErrorType}}>('admin/delete', async (adminId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/admins/${adminId}`)
        dispatch(updateAdminState({key: 'dialogue', value: false}))
        dispatch(updateAdminState({key: 'model', value: null}))
        await dispatch(fetchAdmin(null))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})


export const updateProfile = createAsyncThunk<{data: any }, {}|null, {rejectValue: { data: ResponseErrorType }}>('update/profile', async (payload, {rejectWithValue}) => {
    try {
        const response =  await request.put('/update-profile', { ...payload })
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})
export const { adminInputChange, updateAdminState } = adminSlice.actions;

export default adminSlice.reducer;