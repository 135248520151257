import axios from 'axios';
const apiURL = process.env.NEXT_PUBLIC_API_ENDPOINT;
import { notification } from 'antd';
import { eraseCookie, getCookie } from './helpers.utils';

axios.defaults.baseURL = apiURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.response.use(
  function (response) {
    if (response.data && response.data.message) {
      notification.success({
        message: 'Success',
        description: response.data.message,
      });
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.data) {
      switch (error.response.status) {
        case 401:
          eraseCookie('_mt_jt_at');
          location.href = '/login';
          break;
        default:
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            notification.error({
              message: 'Failed',
              description: error.response.data.message,
            });
          break;
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  if (typeof window !== 'undefined') {
    config.headers.Authorization = `Bearer ${getCookie('_mt_jt_at')}`;
  }
  return config;
});
export default axios;
