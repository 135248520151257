import { Category } from "@/types/model.types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const categoryServiceAPI = createApi({
    reducerPath: 'categoryAPI',
    refetchOnFocus: true,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NEXT_PUBLIC_API_ENDPOINT
    }),
    endpoints: (builder) => ({
        getCategories: builder.query<Category[], null>({
            query: () => '/categories'
        })
    })
})

export const { useGetCategoriesQuery } = categoryServiceAPI;