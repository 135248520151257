import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { ResponseErrorType, Service, SuccessResponseType } from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";
import { RootState } from "..";

type ServiceState = {
    model: Service | null,
    data: Service[]
    status: 'saving' | 'fetching' | 'idle' | 'deleting',
    dialogue: boolean,
    errors?: ResponseErrorType,
    record: Service | null
}


const initialState: ServiceState = {
    status: 'idle',
    dialogue: false,
    record: null,
    model: createDefaultObject<Service>() as Service,
    data: [],
    errors: {
        message: '',
        errors: []
    }
}

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        serviceInputChange(state, { payload }) {
            let {key, value}: {key: keyof Service, value: any} = payload;
            state.model![key] = value;
        },
        updateServiceState(state, {payload}) {
            const {key, value}: {key: keyof ServiceState, value: any} = payload;
            (state as Record<keyof ServiceState, any>)[key] = value;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitService.pending, (state) => {
            state.status = 'saving';
        });
        builder.addCase(submitService.fulfilled, (state) => {
            state.status = 'idle';
            state.model = createDefaultObject<Service>() as Service
        });
        builder.addCase(submitService.rejected, (state, action) => {
            state.status = 'idle';
            state.errors = action.payload?.data
        })
    }
})

export const submitService = createAsyncThunk<{data: SuccessResponseType<Service> }, Blob[] | null, {rejectValue: { data: ResponseErrorType }}>('service/submit', async (files, {rejectWithValue, getState, dispatch}) => {
    try {
        const { service: { model } } = getState() as RootState;
        let response;
        const formData = new FormData();
        Object.keys(model!).map((key:string) => {
            if (Array.isArray(model![key as keyof typeof model])) {
                const arrayData = model![key as keyof typeof model] as string[];
                arrayData.map((item) => {
                    formData.append(key, item)
                })
            } else {
                formData.append(key, model![key as keyof typeof model]);
            }
        })
        if(files) {
            files.map((file)=>{
                formData.append('images', file)
            })
        }
        if(!model?.id) {
            response = await request.post('/services', formData, { headers: {'Content-Type': 'multipart/form-data'} })
        } else {
            response = await request.put(`/services/${model?.id}`, {...model })
        }
        dispatch(fetchService(null))
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export const fetchService = createAsyncThunk<{data: any}, String|null, {rejectValue: {data: ResponseErrorType}}>('services/fetch', async (_, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateServiceState({key: 'status', value: 'fetching'}))
        const response =  await request.get('/services?withImages=true')
        dispatch(updateServiceState({key: 'data', value: response.data.rows}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    } finally {
        dispatch(updateServiceState({key: 'status', value: 'idle'}))
    }
})

export const deleteService = createAsyncThunk<{data: any}, number, {rejectValue: {data:ResponseErrorType}}>('service/delete', async (serviceId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/services/${serviceId}`)
        dispatch(updateServiceState({key: 'dialogue', value: false}))
        dispatch(updateServiceState({key: 'model', value: {}}))
        await dispatch(fetchService(null))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})

export const fetchSingleService = createAsyncThunk<{data: SuccessResponseType<Service>}, String|null, {rejectValue: {data:ResponseErrorType}}>('servicce/fetch', async (slug, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateServiceState({key: 'status', value: 'fetching'}))
        const response =  await request.get(`/services/${slug}`)
        dispatch(updateServiceState({key: 'record', value: response.data}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    } finally {
        dispatch(updateServiceState({key: 'status', value: 'idle'}))
    }
})


export const { serviceInputChange, updateServiceState } = serviceSlice.actions;

export default serviceSlice.reducer;