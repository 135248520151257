import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { ResponseErrorType, FleetsFleetRate, SuccessResponseType } from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";

type FleetRateState = {
    model: FleetsFleetRate | null,
    data: FleetsFleetRate[]
    status: 'saving' | 'fetching' | 'idle' | 'deleting',
    errors?: ResponseErrorType
}

const initialState: FleetRateState = {
    status: 'idle',
    model: createDefaultObject<FleetsFleetRate>() as FleetsFleetRate,
    data: [],
    errors: {
        message: '',
        errors: []
    }
}

const fleetRateSlice = createSlice({
    name: 'fleetrate',
    initialState,
    reducers: {
        updateFleetRateState(state, {payload}) {
            const {key, value}: {key: keyof FleetRateState, value: any} = payload;
            (state as Record<keyof FleetRateState, any>)[key] = value;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitRates.pending, (state) => {
            state.status = 'saving';
        });
        builder.addCase(submitRates.fulfilled, (state) => {
            state.status = 'idle';
            state.model = createDefaultObject<FleetsFleetRate>() as FleetsFleetRate
        });
        builder.addCase(submitRates.rejected, (state, action) => {
            state.status = 'idle';
            state.errors = action.payload?.data
        })
    }
})

export const submitRates = createAsyncThunk<{data: SuccessResponseType<FleetsFleetRate> }, object, {rejectValue: { data: ResponseErrorType }}>('fleet-rate/submit', async (rates, {rejectWithValue, dispatch}) => {
    try {
        const response = await request.post('/fleet-rates', rates)
        dispatch(fetchFleetRate(null))
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export const fetchFleetRate = createAsyncThunk<{data: any}, any, {rejectValue: {data: ResponseErrorType}}>('fleet-rate/fetch', async (_, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateFleetRateState({key: 'status', value: 'fetching'}))
        const response =  await request.get(`/fleet-rates`)
        dispatch(updateFleetRateState({key: 'data', value: response.data.rows}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    } finally {
        dispatch(updateFleetRateState({key: 'status', value: 'idle'}))
    }
})

export const { updateFleetRateState } = fleetRateSlice.actions;

export default fleetRateSlice.reducer;