import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { ResponseErrorType, Fleet, SuccessResponseType } from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";
import { RootState } from "..";

type FleetState = {
    model: Fleet | null,
    data: Fleet[]
    status: 'saving' | 'fetching' | 'idle' | 'deleting',
    dialogue: boolean,
    errors?: ResponseErrorType
    record: Fleet | null,
    dynamicImage?: ImageData,
}

const initialState: FleetState = {
    status: 'idle',
    dialogue: false,
    model: createDefaultObject<Fleet & { has_main_file: boolean }>() as Fleet & { has_main_file: boolean },
    record: null,
    data: [],
    errors: {
        message: '',
        errors: []
    }
}

const fleetSlice = createSlice({
    name: 'fleet',
    initialState,
    reducers: {
        fleetInputChange(state, { payload }) {
            let {key, value}: {key: keyof Fleet, value: any} = payload;
            (state.model  as Partial<Fleet>)[key] = value;
        },
        updateFleetState(state, {payload}) {
            const {key, value}: {key: keyof FleetState, value: any} = payload;
            (state as Record<keyof FleetState, any>)[key] = value;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitFleet.pending, (state) => {
            state.status = 'saving';
        });
        builder.addCase(submitFleet.fulfilled, (state) => {
            state.status = 'idle';
            state.model = createDefaultObject<Fleet>() as Fleet
        });
        builder.addCase(submitFleet.rejected, (state, action) => {
            state.status = 'idle';
            state.errors = action.payload?.data
        })
    }
})

export const submitFleet = createAsyncThunk<{data: SuccessResponseType<Fleet> }, Blob[] | null, {rejectValue: { data: ResponseErrorType }}>('fleet/submit', async (files, {rejectWithValue, getState, dispatch}) => {
    try {
        const { fleet: { model } } = getState() as RootState;
        let response;
        const formData = new FormData();
        Object.keys(model!).map((key:string) => {
            if (Array.isArray(model![key as keyof typeof model])) {
                const arrayData = model![key as keyof typeof model] as string[];
                arrayData.map((item) => {
                    formData.append(key, item)
                })
            } else {
                formData.append(key, model![key as keyof typeof model]);
            }
        })
        if(files) {
            files.map((file)=>{
                formData.append('images', file)
            })
        }
        if(!model?.id) {
            response = await request.post('/fleets', formData, { headers: {'Content-Type': 'multipart/form-data'} })
        } else {
            response = await request.put(`/fleets/${model?.id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        }
        dispatch(fetchFleet({}))
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export const fetchFleet = createAsyncThunk<SuccessResponseType<Fleet>, {withImages?:boolean, withCategory?:boolean, withRates?:boolean}, {rejectValue: {data:ResponseErrorType, status:number}}>('fleets/fetch', async ({withCategory = false, withImages = true, withRates = false}, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateFleetState({key: 'status', value: 'fetching'}))
        const response =  await request.get(`/fleets?withImages=${withImages}&withCategory=${withCategory}&withRates=${withRates}`)
        dispatch(updateFleetState({key: 'data', value: response.data.rows}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType, status:number});        
    } finally {
        dispatch(updateFleetState({key: 'status', value: 'idle'}))
    }
})

export const fetchSingleFleet = createAsyncThunk<{data: SuccessResponseType<Fleet>}, String|null, {rejectValue: {data: ResponseErrorType}}>('fleet/fetch', async (slug, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateFleetState({key: 'status', value: 'fetching'}))
        const response =  await request.get(`/fleets/${slug}`)
        dispatch(updateFleetState({key: 'record', value: response.data}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    } finally {
        dispatch(updateFleetState({key: 'status', value: 'idle'}))
    }
})

export const deleteFleet = createAsyncThunk<{data: any}, number, {rejectValue: {data:ResponseErrorType}}>('fleet/delete', async (fleetId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/fleets/${fleetId}`)
        dispatch(updateFleetState({key: 'dialogue', value: false}))
        dispatch(updateFleetState({key: 'model', value: {}}))
        await dispatch(fetchFleet({}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})

export const deleteFleetImage = createAsyncThunk<{data: any}, number, {rejectValue: {data:ResponseErrorType}}>('fleet/delete-image', async (imageId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/delete-image/${imageId}`)
        // dispatch(updateFleetState({key: 'dialogue', value: false}))
        // dispatch(updateFleetState({key: 'model', value: {}}))
        // dispatch()
        await dispatch(fetchFleet({}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})

export const { fleetInputChange, updateFleetState } = fleetSlice.actions;

export default fleetSlice.reducer;
