import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchExample = createAsyncThunk('example/getContent', async () => {
    const response = await fetch('https://jsonplaceholder.typicode.com/users?_limit=3');
    const data = await response.json();
    return data;
})


const initialState = {
    entities: [],
    counter: 0,
    loading: false,
};

const exampleSlice = createSlice({
    name: 'example',
    initialState,
    reducers: {
        increment: (state) => {
            state.counter++;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchExample.fulfilled, (state, action) => {
            state.entities = action.payload;
            state.loading = false;
        })
        builder.addCase(fetchExample.pending, (state) => {
            state.loading = true;
        })
    }
})
export const { increment } = exampleSlice.actions;
export default exampleSlice.reducer;