import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { Contact, PaginatedList, RejectValue, RejectValueAsyncThunk, ResponseErrorType, SuccessResponseType } from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";
import { RootState } from "..";
import { notification } from "antd";
import { PAGE_SIZE } from "@/utils/constants.utils";

type ContactState = {
    model: Contact | null,
    data: PaginatedList<Contact>,
    isLoading: boolean,
    errors?: ResponseErrorType
}


const initialState: ContactState = {
    isLoading: false,
    model: createDefaultObject<Contact>() as Contact,
    data: {
        count: 0,
        rows: []
    },
    errors: {
        message: '',
        errors: []
    }
}

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        contactInputChange(state, { payload }) {
            let {key, value}: {key: keyof Contact, value:any} = payload;
            (state.model as Record<keyof Contact, any>)[key] = value;
        },
        updateContactState(state, {payload}) {
            const {key, value}: {key: keyof ContactState, value: any} = payload;
            (state as Record<keyof ContactState, any>)[key] = value;
        },
        updateContactList(state, {payload}){
            state.data = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitContact.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(submitContact.fulfilled, (state) => {
            state.isLoading = false;
            notification.success({
                message: 'Thank you',
                description: 'We have received your email! we will contact you shortly'
            })
            state.model = createDefaultObject<Contact>() as Contact
        });
        builder.addCase(submitContact.rejected, (state, action) => {
            state.isLoading = false;
            state.errors = action.payload?.data
            if(state.errors?.message){
                notification.error({
                    message: 'Failed',
                    description: state.errors.message
                })
            }
        })
    }
})

export const submitContact = createAsyncThunk<{data: SuccessResponseType<Contact> }, String|null, {rejectValue: { data: ResponseErrorType }}>('contact/submit', async (payload, {rejectWithValue, getState}) => {
    try {
        const { contact: { model } } = getState() as RootState;
        const response =  await request.post('/contacts', {token: payload, ...model })
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export const fetchContact = createAsyncThunk<SuccessResponseType<Contact>, number | null, RejectValueAsyncThunk>('contacts/fetch', async (pageNum = 1, {rejectWithValue, dispatch}) => {
    try {
        if(!pageNum) pageNum = 1;
        dispatch(updateContactState({key: 'status', value: 'fetching'}))
        const response =  await request.get(`/contacts`, {params: {page: pageNum, pageSize: PAGE_SIZE}})
        dispatch(updateContactList(response.data))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as RejectValue);        
    } finally {
        dispatch(updateContactState({key: 'status', value: 'idle'}))
    }
})


export const replyMessage = createAsyncThunk<{data: SuccessResponseType<Contact> }, object, {rejectValue: { data: ResponseErrorType }}>('contact/reply', async (payload, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateContactState({key: 'status', value: 'saving'}))
        const response =  await request.post('/reply-message', {...payload })
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }finally{
        dispatch(updateContactState({key: 'replyModal', value: false}))
        dispatch(updateContactState({key: 'dialogue', value: false}))
    }
})


export const deleteContact = createAsyncThunk<{data: any}, number, {rejectValue: {data: ResponseErrorType}}>('contact/delete', async (contactId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/contacts/${contactId}`)
        dispatch(updateContactState({key: 'dialogue', value: false}))
        dispatch(updateContactState({key: 'model', value: null}))
        await dispatch(fetchContact(null))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})
export const markAsSeen = createAsyncThunk<{data: any}, number, {rejectValue: {data: ResponseErrorType}}>('contact/seen', async (contactId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.put(`/contact/seen/${contactId}`)
        await dispatch(fetchContact(null))

        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})




export const { contactInputChange, updateContactState, updateContactList } = contactSlice.actions;

export default contactSlice.reducer;