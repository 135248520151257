import { ActionPayload, TotalStopType } from '@/types/index.types';
import { DefaultLocation, Fleet, Reservation } from '@/types/model.types';
import { PAGE_SIZE } from './constants.utils';

export const getChargeType = (chargerType: Fleet['charger_type']) => {
  const chargerTypes = {
    0: 'Charger not available',
    1: 'Both IOS / Android',
    2: 'Ios',
    3: 'Android',
  };
  return chargerTypes[chargerType];
};

export const getStatus = (status: Reservation['status']) => {
  if (!status) return '';
  const reservationStatus = {
    complete: 'badge rounded-pill bg-success',
    pending: 'badge rounded-pill  bg-primary',
    paid: 'badge rounded-pill bg-default',
    refunded: 'badge rounded-pill bg-warning',
    cancelled: 'badge rounded-pill bg-danger',
  };
  return reservationStatus[status];
};

export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; Max-Age=-99999999;';
};

export const getCookie = (name: string) => {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`));

  if (cookieValue) {
    return cookieValue.split('=')[1];
  }

  return null;
};



export const getListType = (listtype: DefaultLocation['list_type']) => {
  if (!listtype) return '';

  const listType = {
    pickup: 'Pick Up',
    dropoff: 'Drop off'
  }

  return listType[listtype]

}

export const getDefaultLocationServiceType = (defaultLocation: DefaultLocation['service_type']) => {
  if (!defaultLocation) return '';
  if (defaultLocation === 'distance'){
    return 'Distance'
  } else if (defaultLocation === 'hourly'){
    return 'Hourly'
  } else{
    return 'Airport Transfer'
  }
}
export const constructQuery = <T, >(payload?:ActionPayload & T) => {
  return {...payload, page: payload?.page || 1, pageSize: payload?.pageSize || PAGE_SIZE}
};


export const getTotalPayablePrice = ({totalDistanceInMeter, fleet, serviceType, hours = null, transferType = 'one-way', totalStops = []} : {totalDistanceInMeter:number, fleet: Fleet, serviceType: string | String, hours?: number | null, transferType?: string, totalStops?: TotalStopType[]}) => { 
  let inMiles:string|number = (totalDistanceInMeter / 1609.34)
  if(transferType === 'return') inMiles = inMiles * 2;
  inMiles = inMiles.toFixed(2)

  if(!fleet) return [inMiles, 0, 0, 0];

  const pricePerMile = fleet.fleet_rate?.per_mile ? fleet.fleet_rate?.per_mile * parseFloat(inMiles) : null;
  const basePrice = pricePerMile! > fleet.fleet_rate?.flat_rate! ? null : (fleet.fleet_rate?.flat_rate! - pricePerMile!);

  let totalAmount = pricePerMile || 0
  if(basePrice)
    totalAmount += basePrice
  
  if(serviceType === 'hourly' && hours) {
      totalAmount = fleet.fleet_rate?.per_hour! * (hours)
  }

  if(fleet.fleet_rate?.waiting_time && parseInt((fleet.fleet_rate?.waiting_time || '0'), 10) > 0) {
    

    let amount = totalStops.filter((stopInfo) => parseInt((stopInfo.waiting_time || '0'), 10) > 1).map((stopInfo) => {
      return (((parseInt(stopInfo.waiting_time!, 10) * 60) * parseInt((fleet.fleet_rate?.waiting_time || '0'), 10)) / 100)
    })
    totalAmount = (totalAmount * 1) + amount.reduce((a, b)=>a + b, 0)
  }
  

  return [inMiles, pricePerMile, basePrice, totalAmount]
}