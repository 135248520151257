import { ResponseErrorType, DefaultLocation, SuccessResponseType } from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";
import { Dispatch, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "@/utils/request.utils";
import { RootState } from "..";
import { constructQuery } from "@/utils/helpers.utils";
import { ActionPayload } from "@/types/index.types";


type DefaultLocationState = {
    model: DefaultLocation | null
    dialogue: boolean,
    data: DefaultLocation[]
    status: 'saving' | 'fetching' | 'idle' | 'deleting',
    errors?: ResponseErrorType
}

const initialState: DefaultLocationState = {
    status: 'idle',
    dialogue: false,
    model: createDefaultObject<DefaultLocation>() as DefaultLocation,
    data: [],
    errors: {
        message: '',
        errors: []
    }
}

const defaultLocationSlice = createSlice({
    name: 'defaultLocation',
    initialState,
    reducers: {
        defaultLocationInputChange(state, { payload }) {
           let {key, value } : { key: keyof DefaultLocation, value: any} = payload;
           (state.model as Record<keyof DefaultLocation, any>)[key] = value
        },
        updateDefaultLocationState(state, {payload}) {
            const {key, value}: {key: keyof DefaultLocationState, value: any} = payload;
            (state as Record<keyof DefaultLocationState, any>)[key] = value;
        }
    }
})

export const fetchDefaultLocation = <T>(payload?: ActionPayload & T) => async (dispatch: Dispatch) => {
    try {
        dispatch(updateDefaultLocationState({ key: 'status', value: 'fetching'}))
        const response = await request.get('/default-locations',  {params: constructQuery<T>(payload)})
        dispatch(updateDefaultLocationState({key: 'data', value: response.data.rows}))
        return response;
    } catch (error) {
        // Exception
    } finally {
        dispatch(updateDefaultLocationState({ key: 'status', value: 'idle'}))
    }
}

export const submitDefaultLocation = createAsyncThunk<{data: SuccessResponseType<DefaultLocation> }, String|null, {rejectValue: { data: ResponseErrorType }}>('defaultlocation/submit', async (_, {rejectWithValue, getState, dispatch}) => {
    try {
        const { defaultLocation: { model } } = getState() as RootState;
        let response;
        if(!model?.id) {
            response = await request.post('/default-locations', {...model })
        } else {
            response = await request.put(`/default-locations/${model?.id}`, {...model })
        }
        dispatch(updateDefaultLocationState({key: 'dialogue', value: false}))
        dispatch(updateDefaultLocationState({key: 'model', value: null}))
        dispatch(fetchDefaultLocation());
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})



export const deleteDefaultLocation = createAsyncThunk<{data: any}, number, {rejectValue: {data: ResponseErrorType}}>('defaultLocation/delete', async (defaultLocationId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/default-locations/${defaultLocationId}`)
        dispatch(updateDefaultLocationState({key: 'dialogue', value: false}))
        dispatch(updateDefaultLocationState({key: 'model', value: null}))
        await dispatch(fetchDefaultLocation())
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})

export const { defaultLocationInputChange, updateDefaultLocationState } = defaultLocationSlice.actions;

export default defaultLocationSlice.reducer;