import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import request from "@/utils/request.utils";
import { Category, ResponseErrorType, SuccessResponseType } from "@/types/model.types";
import { createDefaultObject } from "@/utils/object.partial.utils";
import { RootState } from "..";

type CategoryState = {
    model: Category | null,
    data: Category[]
    status: 'saving' | 'fetching' | 'idle' | 'deleting',
    dialogue: boolean,
    errors?: ResponseErrorType
}


const initialState: CategoryState = {
    status: 'idle',
    dialogue: false,
    model: createDefaultObject<Category>() as Category,
    data: [],
    errors: {
        message: '',
        errors: []
    }
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        categoryInputChange(state, { payload }) {
            let {key, value}: {key: keyof Category, value: any} = payload;
            (state.model as Record<keyof Category, any>)[key] = value;
        },
        updateCateogryState(state, {payload}) {
            const {key, value}: {key: keyof CategoryState, value: any} = payload;
            (state as Record<keyof CategoryState, any>)[key] = value;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submitCategory.pending, (state) => {
            state.status = 'saving';
        });
        builder.addCase(submitCategory.fulfilled, (state) => {
            state.status = 'idle';
            state.model = createDefaultObject<Category>() as Category
        });
        builder.addCase(submitCategory.rejected, (state, action) => {
            state.status = 'idle';
            state.errors = action.payload?.data
        })
    }
})

export const submitCategory = createAsyncThunk<{data: SuccessResponseType<Category> }, String|null, {rejectValue: { data: ResponseErrorType }}>('category/submit', async (_, {rejectWithValue, getState, dispatch}) => {
    try {
        const { category: { model } } = getState() as RootState;
        let response;
        if(!model?.id) {
            response = await request.post('/categories', {...model })
        } else {
            response = await request.put(`/categories/${model?.id}`, {...model })
        }
        dispatch(fetchCategory({onlyParent: false}))
        return response;
    } catch (error: any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    }
})

export const fetchCategory = createAsyncThunk<{data: any}, any, {rejectValue: {data: ResponseErrorType}}>('category/fetch', async ({onlyParent = false, withFleets = false}, {rejectWithValue, dispatch}) => {
    try {
        dispatch(updateCateogryState({key: 'status', value: 'fetching'}))
        const response =  await request.get(`/categories?onlyParent=${onlyParent}&withFleets=${withFleets}`)
        dispatch(updateCateogryState({key: 'data', value: response.data.rows}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});
    } finally {
        dispatch(updateCateogryState({key: 'status', value: 'idle'}))
    }
})

export const deleteCategory = createAsyncThunk<{data: any}, number, {rejectValue: {data: ResponseErrorType}}>('category/delete', async (categoryId, {rejectWithValue, dispatch}) => {
    try {
        const response =  await request.delete(`/categories/${categoryId}`)
        dispatch(updateCateogryState({key: 'dialogue', value: false}))
        dispatch(updateCateogryState({key: 'model', value: null}))
        await dispatch(fetchCategory({onlyParent: false}))
        return response;
    } catch(error:any) {
        return rejectWithValue(error.response as {data: ResponseErrorType});        
    }
})

export const { categoryInputChange, updateCateogryState } = categorySlice.actions;

export default categorySlice.reducer;