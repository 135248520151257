import App from 'next/app';

import type { AppProps } from 'next/app';
import { ReduxProviders } from '@/comp/ReduxProvider';
import { Category, Service } from '@/types/model.types';
import AppHeader from '@/comp/AppHeader';
import {
  QuestionCircleFilled
} from '@ant-design/icons';
import '@/styles/globals.css';
import '@/assets/styles/css/plugins.css';
import '@/assets/styles/css/style.css';
import '@/assets/styles/css/coloring.css';
import '@/assets/styles/css/scheme-01.css';
import '@/assets/styles/scss/_custom.scss';
import AppFooter from '@/comp/AppFooter';
import request from '@/utils/request.utils';
import Head from 'next/head';
import { Button } from 'antd';
export default function MtEverestLimo({
  Component,
  pageProps,
  categories,
  services,
}: AppProps & {
  categories: Category[];
  services: Service[];
  isAuthenticated?: boolean | false;
}) {
  return (
    <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
      <meta name="author" content="mteverestlimo.com"/>

      <meta name="theme-color" content="#00b365 " />
                <meta name="google-site-verification" content="H68s4dU1JeGmW4Ht1sxIfBEES6yImgq-V2HZGEi_usg" />

                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
                    integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
                    crossOrigin="anonymous" />
                {/* <link href={'https://rojal.s3.ap-south-1.amazonaws.com/rojal/favicon-96x96.png'} rel={'icon'} /> */}

                <title>{'Welcome - MT EVEREST LIMO LLC'}</title>
                <meta name="description"
                    content={"Discover unparalleled luxury and convenience with MT EVEREST LIMO. We specialize in fleet bookings for airport transfers and lavish journeys. Whether you need seamless airport transportation or want to elevate your travel experience, our premium fleet booking services have you covered. Explore our range of vehicles and enjoy a first-class ride with MT EVEREST LIMO."} />
                {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1458099779120656"
                    crossorigin="anonymous"></script> */}
                <meta name="robots" content="index, follow" />

                {/* Share content */}
                <meta property="og:url" content={"https://www.mteverestlimo.com"} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={'Welcome - MT EVEREST LIMO'} />
                <meta property="og:description"
                    content={'Discover unparalleled luxury and convenience with MT EVEREST LIMO. We specialize in fleet bookings for airport transfers and lavish journeys. Whether you need seamless airport transportation or want to elevate your travel experience, our premium fleet booking services have you covered. Explore our range of vehicles and enjoy a first-class ride with Mt Everest Limo.'} />
                <meta property="og:image"
                    content={"https://mounteverestlimo.sfo3.cdn.digitaloceanspaces.com/assets/limousine-logo.webp"} />
                <meta property="og:image:url"
                    content={"https://mounteverestlimo.sfo3.cdn.digitaloceanspaces.com/assets/limousine-logo.webp"} />
                <meta property="og:image:alt"
                    content={'Fleet bookings for airport transfers and lavish journeys'} />
                <link rel="canonical" href="https://mounteverestlimo.com" />

                {/* <meta property="fb:app_id" content="2234768930107771" /> */}
                <meta property="description"
                    content={"Discover unparalleled luxury and convenience with Mt Everest Limo. We specialize in fleet bookings for airport transfers and lavish journeys. Whether you need seamless airport transportation or want to elevate your travel experience, our premium fleet booking services have you covered. Explore our range of vehicles and enjoy a first-class ride with Mt Everest Limo."} />

                <meta name="keywords"
                    content={'Fleet Booking Services, Airport Transfer Services, Luxury Transportation, Chauffeured Mt Everest Limo Services, Corporate Fleet Rentals, Ground Transportation Solutions, VIP Car Services, Executive Airport Transfers, Premium Fleet Rentals, Limo Rental for Special Occasions, Professional Chauffeurs, Corporate Travel Solutions, Reliable Fleet Booking, Comfortable Airport Shuttle, Group Transportation Services, Exclusive Travel Experiences, Luxury Vehicle Rentals, Business Class Car Services, Private Fleet Reservations, Mt Everest Limo'} />
                <meta property="og:site_name" content="Mt Everest Limo" />
    </Head>
      <ReduxProviders>
        <div id='wrapper'>
          <AppHeader categories={categories} services={services} />
          <Component {...pageProps} />
        </div>
        <AppFooter />

      <div className='book-now-mooovs'>
        <Button onClick={() => {
          window.open('https://customer.moovs.app/mteverestlimogmailcom/new/info', '_blank');
        }} size='large' icon={<QuestionCircleFilled />} type='primary'>Book Now</Button>
      </div>

      </ReduxProviders>
    </>
  );
}

MtEverestLimo.getInitialProps = async (appContext: any) => {
  if (
    appContext.ctx.req &&
    !!!appContext.ctx.req.cookies._mt_jt_at &&
    appContext.ctx.pathname.includes('/dashboard')
  ) {
    appContext.ctx.res.writeHead(307, { Location: '/login' });
    appContext.ctx.res.end();
  }
  const services = await request.get('/services');
  const categories = await request.get('/categories?withFleets=true');
  const appProps = await App.getInitialProps(appContext);
  return {
    ...appProps,
    categories: categories.data.rows,
    services: services.data.rows,
    isAuthenticated:
      appContext.ctx.req && !!appContext.ctx.req.cookies._mt_jt_at,
  };
};
